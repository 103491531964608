import { USER_ROLE } from "constants/AppConstant";
import { FaUserSecret } from "react-icons/fa";
import leaveSetupRoutes from "./leaveSetupRoutes";
import userExtraWorkingScheduleRoutes from "./userExtraWorkingScheduleRoutes";
import userProfileRoutes from "./userProfileRoutes";
import userRegistrationListRoutes from "./userRegistrationListRoutes";
import userRegistrationRoutes from "./userRegistrationRoutes";
import userRegularWorkingScheduleRoutes from "./userRegularWorkingScheduleRoutes";
import bulkUpdateRegularWorkingScheduleRoutes from "./bulkUpdateRegularWorkingScheduleRoutes";

const routes = {
  title: "User ",
  permittedRole: [USER_ROLE.SUPER_ADMIN],
  type: "collapse",
  icon: <FaUserSecret />,
  path: ["/userMenu"],
  children: [
    userRegistrationRoutes,
    userRegistrationListRoutes,
    userProfileRoutes,
    userRegularWorkingScheduleRoutes,
    userExtraWorkingScheduleRoutes,
    leaveSetupRoutes,
    bulkUpdateRegularWorkingScheduleRoutes
  ],
};

export default routes;
