import PropTypes from "prop-types";
import React from "react";
import FeesConcessionVerificationProvider from "./feesConcessionVerificationContext";

const AccountContextProvider = ({ children }) => {
    return <FeesConcessionVerificationProvider>{children}</FeesConcessionVerificationProvider>;
};

export default AccountContextProvider;

AccountContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
