import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const LiveClassSetup = React.lazy(() =>
    import("modules/liveClass/liveClassSetup")
);

const routes = {
    element: <LiveClassSetup />,
    title: "Live Class Setup",
    type: "item",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    path: [
        "/liveclass/schedule",
        "/liveclass/type",
    ],
};

export default routes;
