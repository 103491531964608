// React imports
import { createContext, useContext, useReducer } from "react";

// Generic API helper
import ApiHelper from "helpers/ApiHelper";

// Constant API names and default api params
import { API_NAMES } from "constants/ApiConstants";

// Reducer for changing state
import reducer from "reducers/whatsapp/whatsappSessionReducer";

// context to be used in the app
const WhatsappSessionContext = createContext();

// hook to use the context states in the ui
const useWhatsappSessionContext = () => {
    return useContext(WhatsappSessionContext);
};
export { useWhatsappSessionContext };

// Initial State
const initialState = {
    item: "",
};

// provider of the context
const WhatsappSessionProvider = ({ children }) => {
    // API helper functions to hit APi's
    const { CreateAPI, DeleteAPI } = ApiHelper();

    // Reducer to set state
    const [state, dispatch] = useReducer(reducer, initialState);

    // Name of the api to hit
    const apiName = API_NAMES.WHATSAPP.WHTATSAPP_SESSION;


    // Add new record in records of DB
    const createItem = (formData, setSubmitting, resetForm, handleAddClose, onSuccess, onError) => {
        CreateAPI(
            apiName,
            dispatch,
            formData,
            setSubmitting,
            resetForm,
            handleAddClose,
            null,
            onSuccess,
            onError,
        );
    };

    return (
        <WhatsappSessionContext.Provider
            value={{
                item: state.item,
                createItem,
            }}
        >
            {children}
        </WhatsappSessionContext.Provider>
    );
};

export default WhatsappSessionProvider;
