import { USER_ROLE } from "constants/AppConstant";
import assignmentDataUploadRoutes from './assignmentDataUploadRoutes';
import assignmentFormRoutes from './assignmentFormRoutes';
import assignmentSetupRoutes from "./assignmentSetupRoutes";
import assignmentSummaryRoutes from "./assignmentSummaryRoutes";
import studentAssignmentRoutes from "./studentAssignmentRoutes";

import { BsFillClipboardFill } from "react-icons/bs";
const menu = {
    id: "Assignment",
    title: "Assignment",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "collapse",
    icon: < BsFillClipboardFill />,
    path: ["/assignmentMenu"],
    children: [assignmentSetupRoutes, assignmentFormRoutes, studentAssignmentRoutes, assignmentDataUploadRoutes, assignmentSummaryRoutes],
};

export default menu;
