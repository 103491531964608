// ForJWT Auth
// import { getUserFromJwtAuth } from "@crema/helpers/AuthHelper";
import {
  useJWTAuth,
  useJWTAuthActions,
} from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import { useMemo } from "react";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading, permissions } = useJWTAuth();
  const memoizedReturn = useMemo(() => ({
    isLoading,
    isAuthenticated,
    permissions,
    user: user,
  }), [user, isAuthenticated, isLoading, permissions]);
  return memoizedReturn;
};

export const useAuthMethod = () => {
  const jwtActions = useJWTAuthActions();

  return jwtActions;
};
