import { USER_ROLE } from "constants/AppConstant";
import React from "react";

const FeesConcessionVerification = React.lazy(() => import("modules/account/feesConcessionVerification"));

const routes = {
    element: <FeesConcessionVerification />,
    title: "Concession Verification",
    permittedRole: [USER_ROLE.SUPER_ADMIN],
    type: "item",
    path: ["/account/feesconcessionverification"],
};

export default routes;
