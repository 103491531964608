import AppSearchBar from "@crema/components/AppSearchBar";
import debounce from "lodash/debounce";
import React, { useCallback } from "react";

const SearchWithDebounce = ({
  setSearch = () => {},
  sx = {},
  width,
  placeholder = "Search Here...",
  fullWidth,
  hasSearchIcon = true,
}) => {
  const handleSearchFromAPI = (query) => {
    setSearch(query);
  };

  const debouncedHandleSearch = useCallback(
    debounce(handleSearchFromAPI, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedHandleSearch(e.target.value);
  };

  return (
    <AppSearchBar
      iconPosition="right"
      hasSearchIcon={hasSearchIcon}
      overlap={false}
      onChange={handleSearchChange}
      placeholder={placeholder}
      width={width}
      sx={sx}
      fullWidth={fullWidth}
    />
  );
};

export default React.memo(SearchWithDebounce);
