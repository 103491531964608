import PropTypes from "prop-types";
import WhatsappSessionProvider from "./whatsappSessionContext";

const WhatsappContextProvider = ({ children }) => {
  return <WhatsappSessionProvider>{children}</WhatsappSessionProvider>;
};

export default WhatsappContextProvider;

WhatsappContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
